<template>
  <div>
    <loader fullScreen v-if="loading" />
    <!-- -- nav ---------------------------- -->
    <nav-bar fixed />

    <!-- -- header ------------------------- -->
    <header :style="{ backgroundImage: `url('${header.img}')` }">
      <section class="container">
        <h1 class="title">{{ header.title }}</h1>
        <p class="subtitle">{{ header.sub }}</p>
      </section>
    </header>

    <!-- -- cards -------------------------- -->
    <div class="container">
      <div class="cards">
        <info-card
          v-for="c in cards"
          :key="c.id"
          :icon="c.icon_name"
          :title="c.title"
          :desc="c.description"
        />
      </div>
    </div>

    <!-- -- about -------------------------- -->
    <about-us class="page-section" />

    <!-- -- services ----------------------- -->
    <service-section class="page-section" />

    <!-- -- contact ------------------------ -->
    <contact-grid class="page-section" />
  </div>
</template>

<script>
import NavBar from "@/components/nav-bar.vue";
import InfoCard from "@/components/info-card.vue";
import AboutUs from "@/components/about-us.vue";
import ServiceSection from "@/components/service-section.vue";
import ContactGrid from "@/components/contact-grid.vue";

import axios from "../plugins/axios";

export default {
  name: "Home",
  title: "Depaco NV - Home",
  components: { NavBar, InfoCard, AboutUs, ServiceSection, ContactGrid },

  data() {
    return {
      loading: false,
      header: {
        title: "",
        sub: "",
        img: ""
      },
      cards: [
        {
          id: "1",
          icon_name: "chart-line",
          title: "loading",
          desc: "loading..."
        },
        {
          id: "2",
          icon_name: "chart-line",
          title: "loading",
          desc: "loading..."
        },
        {
          id: "3",
          icon_name: "chart-line",
          title: "loading",
          desc: "loading..."
        }
      ]
    };
  },

  async mounted() {
    this.loading = true;
    const [header, infoCards] = await Promise.all([
      axios.get("homepageheader/single"),
      axios.get("infocard?limit=3")
    ]);

    // header content
    this.header.title = header.data.title;
    this.header.sub = header.data.subtitle;
    this.header.img = header.data.image.uri;

    // info cards
    this.cards = infoCards.data.results;

    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
$opacity: 0.7;

// -- header -------------------------------
header {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  align-items: center;

  &::after {
    z-index: 1;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba($secondary, $opacity);
  }
}

// -- header section -----------------------
header section {
  color: white;
  z-index: 2;

  h1.title {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 400;
    margin: unset;
  }

  p.subtitle {
    margin: unset;
    font-size: 0.9rem;
  }
}

// -- info cards ---------------------------
.cards {
  margin-top: -7rem;
  position: relative;

  @media (min-width: $medium) {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
}

// -- sections -----------------------------
section.page-section {
  margin: 5rem 0;
}
</style>
