<template>
  <article class="info-card">
    <!-- -- icon circle -------------------- -->
    <div class="icon-circle">
      <fa :icon="icon" />
    </div>

    <!-- -- title -------------------------- -->
    <h1 class="title">{{ title }}</h1>

    <!-- -- description -------------------- -->
    <p class="description">{{ desc }}</p>
  </article>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    title: String,
    desc: String,
    icon: String
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

// -- info card ----------------------------
.info-card {
  z-index: 10;
  background-color: white;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: $radius;
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-grow: 1;

  &:last-child {
    margin-bottom: unset;
  }

  @media (min-width: $medium) {
    margin-bottom: unset;
  }
}

// -- icon ---------------------------------
.icon-circle {
  position: relative;
  color: $primary;
  background-color: $grey;
  width: 40%;
  border-radius: $radius-round;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;
  }
}

// -- title --------------------------------
h1.title {
  font-weight: normal;
  text-transform: uppercase;
  color: #000;
  margin: 1.5rem 0;
}

// -- description --------------------------
p.description {
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.3rem;
  color: $text-accent;
  margin: unset;
}
</style>
