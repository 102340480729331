<template>
  <section class="contact-grid">
    <div class="contact-card">
      <!-- -- map -------------------------- -->
      <div class="map">
        <iframe
          title="Google Maps embed"
          width="100%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=nl&amp;q=Schatting%2055,%20Zedelgem+(Depaco)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>

      <!-- -- info ------------------------- -->
      <div class="info">
        <h1 class="title">contacteer ons</h1>

        <div class="details">
          <contact-icon icon="envelope" type="mail" value="patrick@depaco.be" />
          <contact-icon icon="phone-alt" type="tel" value="+32 477 78 91 34" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactIcon from "@/components/contact-icon.vue";

export default {
  name: "ContactGrid",
  components: { ContactIcon }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
$card-width: 900px;

// -- contact card -------------------------
.contact-card {
  width: calc(100% - 2rem);
  max-width: $card-width;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;

  @media (min-width: $container-size) {
    flex-direction: row;

    .map,
    .info {
      width: 50%;
    }
  }
}

// -- map slot -----------------------------
.map {
  width: 100%;

  iframe {
    display: block;
  }
}

// -- info slot ----------------------------
.info {
  position: relative;

  h1.title {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin: unset;
    font-size: $title-size;
    text-transform: uppercase;
    font-weight: 500;
    color: $primary;
    text-align: center;
    padding: 2rem 0;
  }

  .details {
    display: flex;
    height: 100%;
    min-height: 20rem;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
