<template>
  <div>
    <router-view />
    <footer-section />
  </div>
</template>

<script>
import FooterSection from "./components/footer-section.vue";
export default {
  components: { FooterSection }
};
</script>

<style lang="scss">
@import "@/scss/global.scss";
</style>
