import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChartLine,
  faHandHoldingUsd,
  faCheckDouble,
  faQuoteLeft,
  faPhoneAlt,
  faEnvelope,
  faImages,
  faAngleLeft,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faChartLine);
library.add(faHandHoldingUsd);
library.add(faCheckDouble);
library.add(faQuoteLeft);
library.add(faPhoneAlt);
library.add(faEnvelope);
library.add(faImages);
library.add(faAngleLeft);
library.add(faAngleRight);

Vue.component("fa", FontAwesomeIcon);
