<template>
  <nav :class="{ 'is-fixed': fixed, 'show-color': showColor }">
    <div class="nav-container">
      <!-- -- logo ------------------------- -->
      <router-link to="/" class="logo-link">
        <depaco-logo />
      </router-link>
      <div class="spacer"></div>
      <!-- -- links ------------------------ -->
      <div class="links" :class="{ 'is-dark': !fixed || showColor }">
        <router-link to="/">home</router-link>
        <router-link to="/projects">projects</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import DepacoLogo from "@/components/depaco-logo.vue";
// import debounce from "lodash/debounce";

export default {
  name: "NavigationBar",
  components: { DepacoLogo },
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showColor: false
    };
  },

  created() {
    if (this.fixed) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  destroyed() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.showColor = window.scrollY > 50;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

// -- logo ---------------------------------
.logo-link {
  height: 100%;
}
img.logo {
  padding: 0.6rem 0;
  box-sizing: border-box;
}

// -- nav ----------------------------------
nav {
  z-index: 1000;
  background-color: $primary;
  width: 100vw;
  height: $header-size;
  transition: background-color 0.3s ease-out;

  &.is-fixed {
    background-color: unset;
    position: fixed;
    top: 0;
    left: 0;
  }

  &.show-color {
    background-color: $primary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

// -- nav container ------------------------
.nav-container {
  max-width: $container-size;
  margin: 0 auto;
  box-sizing: border-box;
  height: $header-size;

  display: flex;
  align-items: center;

  @media (max-width: $container-size) {
    padding: 0 1rem;
  }
}

// -- links --------------------------------
.links a {
  color: white;
  text-decoration: unset;
  text-transform: uppercase;
  height: $header-size;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  font-weight: 500;
  font-size: 0.8rem;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;

  &:hover {
    color: $primary;
  }
}

.links.is-dark a:hover {
  color: white;
  background-color: $secondary;
}
</style>
