<template>
  <div class="contact-icon">
    <div class="icon-circle">
      <fa :icon="icon" />
    </div>
    <a class="value" :href="`${prefix}${value}`">{{ value }}</a>
  </div>
</template>

<script>
export default {
  name: "ContactIcon",
  props: {
    icon: String,
    value: String,
    type: String
  },
  computed: {
    prefix() {
      if (this.type === "tel") return "tel:";
      else if (this.type === "mail") return "mailto:";
      else return "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

// -- contact icon -------------------------
.contact-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: unset;
  }
}

// -- icon circle --------------------------
.icon-circle {
  width: 3.5rem;
  height: 3.5rem;
  background-color: $primary-dark;
  border-radius: $radius-round;
  position: relative;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    color: white;
  }
}

// -- value --------------------------------
a.value {
  color: $text;
  text-decoration: none;
  transition: color 0.2s ease-out;
  width: 10rem;
  margin-left: 1rem;

  &:hover {
    color: $primary;
  }
}
</style>
