<template>
  <section class="about-us">
    <!-- -- title -------------------------- -->
    <h1 class="title">{{ title }}</h1>

    <!-- -- left pane ---------------------- -->
    <div class="left">
      <div class="inner-container">
        <img src="@/assets/team-spirit.svg" alt="spirit icon of team" />
      </div>
    </div>

    <!-- -- right pane --------------------- -->
    <div class="right">
      <div class="inner-container">
        <!-- -- main text -------------------- -->
        <p class="main" v-html="intro"></p>
        <!-- -- spacer ----------------------- -->
        <div class="spacer" />

        <!-- -- quote ------------------------ -->
        <fa icon="quote-left" class="icon" />
        <p class="quote" v-html="quote"></p>
        <hr />
        <p class="quoter">
          {{ quotee }}, <span>{{ quoteeRole }}</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "../plugins/axios";

export default {
  name: "AboutUs",

  data() {
    return {
      title: "",
      intro: "",
      quote: "",
      quotee: "",
      quoteeRole: ""
    };
  },

  async mounted() {
    const content = await axios.get("aboutcontent/single");

    // update content
    this.title = content.data.title;
    this.intro = content.data.intro;
    this.quote = content.data.quote;
    this.quotee = content.data.quotee;
    this.quoteeRole = content.data.quotee_role;
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

// -- variables ----------------------------
$offset: 13%;
$height: 60vh;
$w-offset: 10rem;

// -- main styling -------------------------
.about-us {
  width: 100%;
  height: $height;
  position: relative;
  z-index: 0;

  @media (max-width: $medium) {
    height: 85vh;
  }

  .left,
  .right {
    width: calc(50% + #{$w-offset});
    height: 100% - $offset;
    position: absolute;
    z-index: -1;
    box-sizing: border-box;

    @media (max-width: $medium) {
      width: 100%;
    }
  }

  .left {
    top: 0;
    left: 0;
    background-color: $primary;
  }

  .right {
    bottom: 0;
    right: 0;
    background-color: $greyer;
  }
}

// -- title --------------------------------
h1.title {
  position: absolute;
  margin: unset;
  left: 50%;
  font-size: $title-size;
  top: calc(#{$offset / 2} - #{$title-size / 2});
  transform: translateX(-50%);
  color: white;
  text-transform: uppercase;
  font-weight: 500;
}

// -- right --------------------------------
.right .inner-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .main {
    padding-left: 25%;
    text-align: right;
    font-size: 1.15rem;
    line-height: 1.5rem;
  }

  .icon {
    color: $primary;
    font-size: 3.5rem;
  }

  hr {
    width: 7rem;
    margin-left: unset;
    border-top: 3px solid $primary;
  }

  .quote {
    font-size: 1.3rem;
    padding-left: 2rem;
    line-height: 1.8rem;
  }

  .quoter {
    font-size: 0.9rem;

    span {
      font-size: 0.8rem;
    }
  }

  /deep/ span {
    color: $primary;
  }
}

// -- left ---------------------------------
.left .inner-container {
  display: flex;
  height: 100%;
}

// -- inner container ----------------------
.inner-container {
  padding: 2rem 0 2rem 2rem;
  box-sizing: border-box;
  width: calc(500px + #{$w-offset});

  @media (max-width: 1000px) {
    width: 100%;
    padding: 2rem;
  }
}

.left .inner-container {
  margin-left: auto;
}
</style>
