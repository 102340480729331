<template>
  <div class="wrapper" :class="{ 'is-full-screen': fullScreen }">
    <div class="loader" :class="{ 'is-full-screen': fullScreen }" />
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

$size: 6rem;

// -- loader -------------------------------
.loader {
  border: $size / 10 solid darken($grey, 7%);
  border-top: $size / 10 solid $primary;
  box-sizing: border-box;
  border-radius: 50%;
  width: $size;
  height: $size;
  animation: spin 2s linear infinite;

  &.is-full-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// -- wrapper ------------------------------
.wrapper.is-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 2000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
