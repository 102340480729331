<template>
  <section class="services">
    <div class="container">
      <!-- -- title & desc ----------------- -->
      <h1 class="title">{{ title }}</h1>
      <p class="explanation">{{ description }}</p>

      <!-- -- service grid ----------------- -->
      <div class="service-grid">
        <div class="item" v-for="s in services" :key="s.id">
          <span>{{ s.name }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "../plugins/axios";

export default {
  name: "ServiceSection",

  data() {
    return {
      title: "",
      description: "",
      services: []
    };
  },

  async mounted() {
    const [services, content] = await Promise.all([
      axios.get("service"),
      axios.get("servicecontent/single")
    ]);

    // update content
    this.title = content.data.title;
    this.description = content.data.description;
    this.services = services.data.results;
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

// -- services -----------------------------
.services {
  background-color: $greyer;
}

// -- title --------------------------------
h1.title {
  font-size: $title-size;
  font-weight: 500;
  margin: unset;
  padding: 2rem 0;
  text-align: center;
  color: $primary;
  text-transform: uppercase;
}

// -- explanation --------------------------
p.explanation {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

// -- service grid -------------------------
.service-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 4rem 0;
}

// -- grid item ----------------------------
$padding-item: 0.5rem;
.service-grid .item {
  background-color: $primary;
  color: white;
  width: calc(1000px / 5.5);
  position: relative;
  padding: $padding-item;
  box-sizing: border-box;
  transition: background-color 0.2s ease-out;

  @media (max-width: $small) {
    width: calc(50% - 0.5rem);
  }

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &:hover {
    background-color: $primary-dark;
  }

  span {
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    width: calc(100% - #{$padding-item * 2});
    height: calc(100% - #{$padding-item * 2});
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
