import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import titleMixin from "@/plugins/title.js";
import "@/plugins/fontawesome.js";
import "@/plugins/loader.js";

Vue.config.productionTip = false;

Vue.mixin(titleMixin);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
