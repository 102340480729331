<template>
  <footer>
    <div class="container">
      <div class="content">
        <div class="links">
          <router-link to="/">Home</router-link>
          <router-link to="/projects">Projects</router-link>
        </div>
        <p class="copyright">&copy; Copyright {{ year }}, Dsync.io</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

footer {
  background-color: $secondary;
  color: lighten($secondary, 20%);
  height: 30vh;
}

// -- content ------------------------------
.content {
  display: flex;
  // background-color: fuchsia;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

// -- links --------------------------------
.links a {
  color: lighten($secondary, 30%);
  text-decoration: unset;
  margin-right: 1rem;

  &:last-child {
    margin-right: unset;
  }
}
</style>
