<template>
  <img class="logo" src="@/assets/depaco.svg" alt="depaco logo white" />
</template>

<script>
export default {
  name: "DepacoLogo"
};
</script>

<style lang="scss" scoped>
.logo {
  height: 100%;
  width: auto;
}
</style>
